@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
  --primary-color: #14a800;
  --secondary-color: #57a0ac;
  --third-color: #253B80;
  --black-color: #080E32;
}
body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}
.container {
  width: 100%;
	padding-right:15px;
	padding-left:15px;
	margin-right:auto;
	margin-left:auto
}
img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: var(--secondary-color);
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: var(--secondary-color) !important;
}

.top-bar {
  padding: .5rem 0;
  background: var(--secondary-color);
}

.top-bar a {
  color: #ffffff;
  text-decoration: none;
}

.top-bar .icons a {
  font-size: 22px;
}

.top-bar a:hover {
  color: var(--third-color);
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 5px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
  position: relative;
  display: block;
  color: var(--primary-color);
  margin: 20px 17px;
  text-decoration: none;
  cursor: pointer;
}
.menu-item span {
  padding-left: 5px;
  font-weight: 400;
}
.menu-item .sub_menu a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  display: block;
  color: var(--primary-color);
  margin: 20px 17px;
  text-decoration: none;
  cursor: pointer;
}
.menu-item .get-quote {
  border: 2px solid var(--primary-color);
  padding: 10px;
  border-radius: 50px;
  font-weight: 400;
}

.menu-item .get-quote:hover {
  border: 2px solid var(--secondary-color);
  color: #ffffff !important;
  background-color: var(--primary-color);
}

.header__middle__logo{
  width: 30%;
  display: inline-block;
  float: left;
}

.header__middle__logo .logo_text{
  color: var(--primary-color);
  margin-left: 10px;
}
.header__middle__logo .logo_text:hover{
  color: var(--secondary-color);
}

.navbar_logo_rotate {
    animation: navbar_logo_rotate 3s infinite linear;
}
@keyframes navbar_logo_rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.header__middle__menus {
  width: 70%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
  position: sticky;
  top:0;
  z-index: 9999;
}
.header__middle__logo > a > h1 {
  color: var(--secondary-color);
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: var(--secondary-color)!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: var(--secondary-color);
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: var(--third-color) !important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: var(--secondary-color) !important;
}
.menu-item .sub__menus li:hover a{
  color: var(--secondary-color) !important;
}

.header__middle__logo img{
  max-width: 207px;
}


@media(max-width:991px){
  .top-bar a {
    margin: 0px 5px;
  }

  .header__middle__logo {width: 77%; height: 60px;}
  .header__middle__menus {width: 20%;}
  .header__middle__logo img {max-width: 197px;}
  .main-nav .menubar__button:hover {background-color: var(--secondary-color);}
  .main-nav .menu-item:hover > a {color: var(--third-color) !important}
  .main-nav .menubar__button {
    display: block!important;
    float: right;
    background-color: #080808;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }
  .main-nav .menubar__button svg{font-size: 27px;}
  .main-nav ul.menuq2 {
    display: block!important;
    position: absolute;
    left: 0;
    z-index: 10007;
    background-color: var(--primary-color);
    right: 0;
    padding-bottom: 17px;
    padding-top: 40px;
    
  }
  .main-nav ul.menuq2 .get-quote {
    border: 2px solid white;
    padding: 10px;
    border-radius: 10px;
    width: 40%;
    margin: 0 auto;
  }
  .main-nav ul.menuq2 .get-quote:hover {
    border: 2px solid var(--third-color);
    color: var(--third-color) !important;
  }
  .main-nav .menu-item a {text-align: center;color: white;}
  .main-nav .menu-item .sub_menu a {text-align: center;color: var(--primary-color);}
  .main-nav  ul.main-menu {display: none}
  .main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
  .sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
  .sub__menus__Active2{display: block!important;}
  .menus__categorysss{display: none;}
  .menus__categorysss2{display: none;}
  .menu-item .sub__menus__full a {text-align: left;}

}

/* header-end */

.head_icon_rotate{
    animation: rotation 10s infinite linear;
    width: 450px;
}
.head_icon_title {
    color: var(--primary-color);
}
.head_icon_title_button{
    margin-top: 30px;
}
.head_icon_title_button a{
    text-decoration: none;
    color: var(--primary-color);
    border: 2px solid var(--secondary-color);
    border-radius: 50px;
    padding: 8px 30px;
    font-size: 20px;
    transform: translate(-50%, -50%);
    background: #fff;
    animation: lets_button 1.5s infinite
}
@keyframes lets_button {
    0% {
        -moz-box-shadow: 0 0 0 0 var(--secondary-color);
        box-shadow: 0 0 0 0 var(--secondary-color)
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(254, 141, 99, 0);
        box-shadow: 0 0 0 10px rgba(254, 141, 99, 0)
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(254, 141, 99, 0);
        box-shadow: 0 0 0 0 rgba(254, 141, 99, 0)
    }
}
@-webkit-keyframes lets_button {
    0% {
        -webkit-box-shadow: 0 0 0 0 var(--secondary-color)
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(254, 141, 99, 0)
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(2254, 141, 99, 0)
    }
}

.head_icon_title_button a:hover{
    color: var(--secondary-color);
    animation: lets_button_hover 1.5s infinite;
}
@keyframes lets_button_hover {
    0% {
        -moz-box-shadow: 0 0 0 0 var(--primary-color);
        box-shadow: 0 0 0 0 var(--primary-color)
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(254, 141, 99, 0);
        box-shadow: 0 0 0 10px rgba(254, 141, 99, 0)
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(254, 141, 99, 0);
        box-shadow: 0 0 0 0 rgba(254, 141, 99, 0)
    }
}
@-webkit-keyframes lets_button_hover {
    0% {
        -webkit-box-shadow: 0 0 0 0 var(--primary-color)
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(254, 141, 99, 0)
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(2254, 141, 99, 0)
    }
}



/*Btn Style Three*/

.btn-style-three {
    position: relative;
    padding: 11px 40px;
    line-height: 24px;
    text-transform: capitalize;
    background: #ffffff;
    color: var(--third-color);
    font-size: 15px;
    font-weight: 500;
    border: 2px solid #ffffff;
    font-family: 'Poppins', sans-serif;
    border-radius: 50px;
    text-decoration: none;
}

.btn-style-three:hover {
    background: none;
    color: #ffffff;
    border-color: #ffffff;
}

/*** 
====================================================================
	Why We Are?...
====================================================================
***/


.green-dev {
    margin-top: 50px;
    margin-bottom: 40px;
}

.green-dev .inner-content p {
    text-align: justify;
    text-justify: inter-word;
    text-indent: 70px;
    font-weight: 400;
}
.content-column h2 {
    position: relative;
    color: #13192d;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 25px;
    margin-top: 25px;
}

.content-column p {
    position: relative;
    color: #13192d;
    font-size: 18px;
    line-height: 1.6em;
    margin-bottom: 25px;
}


.list-style-one {
    position: relative;
}

.green-list {
    width: 47%;
    display: inline-block;
    vertical-align: top;
}

.list-style-one li {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.8em;
    color: rgba(23, 24, 26, 0.973);
}

.list-style-one li:before {
    position: absolute;
    font-family: "FontAwesome";
    content: '\f0a9';
    left: 0px;
    top: 2px;
    color: var(--primary-color);
}

.section-satisfaction-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 480px;
    height: 2px;
    background: var(--third-color);
    bottom: 0px;
    left: calc(50% - 240px);
}

.section-satisfaction-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 150px;
    height: 6px;
    background: var(--primary-color);
    bottom: -2px;
    left: calc(50% - 75px);
}

.services-section .services-title {
    position: relative;
    padding-bottom: 40px;
    margin-top: 10px;
}


.btn-style-three .icon {
    position: relative;
    margin-left: 8px;
}

.theme-btn.btn-style-three.Learn-more-btn {
    margin-bottom: 10%;
}

.services-section .services-title h2 {
    position: relative;
    color: #13192d;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 40px;
    text-align: right;
}

.services-block .inner-box {
    position: relative;
    text-align: center;
    padding: 50px 10px;
    background-color: var(--third-color);
    border-radius: 40px;
}

.services-block .inner-box .icon-box {
    position: relative;
    margin-bottom: 15px;
}

.services-block .inner-box p {
    position: relative;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
}

.services-block .inner-box a {
    text-decoration: none;
    color: #ffffff;
}

.services-block .inner-box p a:hover {
    color: var(--primary-color);
}

.services-block .inner-box a:hover {
    color: var(--primary-color);
}

.services-block {
    position: relative;
    margin-bottom: 40px;
}

.services-block .inner-box .learn-more {
    position: relative;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
}

.services-block .inner-box .learn-more .arrow {
    position: relative;
    top: 2px;
    margin-left: 5px;
}

.services-block:nth-child(2) .inner-box {
    background-color: #23272c;
}

.services-block:nth-child(3) .inner-box {
    background-color: #d74a4a;
}

.services-block .inner-box .icon-box {
    position: relative;
    margin-bottom: 15px;
}

/*** 
====================================================================
	Offer Section
====================================================================
***/

.offer-section {
    position: relative;
    padding: 0px 0px 15px;
}

.offer-section h2 {
    position: relative;
    color: #13192d;
    font-size: 42px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}
.section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 290px;
    height: 2px;
    background: var(--third-color);
    bottom: 1px;
    left: calc(50% - 145px);
}

.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 70px;
    height: 6px;
    background: var(--primary-color);
    bottom: -2px;
    left: calc(50% - 35px);
}
/*Services Block Two*/

.services-block-two {
    position: relative;
    margin-bottom: 30px;
}

.services-block-two .inner-box {
    position: relative;
    text-align: center;
    padding: 25px 25px 20px;
    background-color: #ffffff;
    min-height: 270px;
}

.services-block-two .inner-box .hover-shadow-box {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    background-color: #ffffff;
}

.services-block-two .inner-box:hover .hover-shadow-box {
    bottom: -20px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(244, 244, 244, 1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(244, 244, 244, 1);
    box-shadow: 0px 0px 20px 0px rgba(20, 168, 0, 1);
}

.services-block-two .inner-box .icon-box {
    position: relative;
    margin-bottom: 20px;
}
.services-block-two .inner-box .icon-box icon {
    visibility: visible; 
    animation-duration: 1100ms; 
    animation-delay: 0ms; 
    animation-name: fadeIn;
}

.services-block-two img {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.6s;
}

.services-block-two:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.news-style-one .inner-box img {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.6s;
}

.news-style-one .inner-box:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(0.9);
}

.services-block-two .inner-box h3 {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
}

.services-block-two .inner-box h3 a {
    text-decoration: none;
    color: #13192d;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-two .inner-box h3 a:hover {
    color: var(--primary-color);
}

.services-block-two .inner-box .text {
    position: relative;
    font-size: 16px;
    line-height: 1.8em;
    margin-bottom: 12px;
    color: rgba(19, 25, 45, 0.75);
}

.services-block-two .inner-box .btn-box {
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}
.services-block-two .inner-box .btn-box a {
    text-decoration: none;
    color: var(--third-color);
    position: relative;
    font-weight: 500;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.7s ease;
    border: 1px solid var(--secondary-color);
    padding: 10px 40px;
    border-radius: 50px;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-two .inner-box:hover .btn-box {
    opacity: 1;
    visibility: visible;
}
.services-block-two .inner-box .btn-box a:hover{
    border: 1px solid var(--secondary-color);
    color: #ffffff;
    background-color: var(--primary-color);
    transition: all 0.7;
    
}

.services-block-two.style-two .inner-box {
    position: relative;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.services-block-two.style-two .inner-box h3 a {
    color: #ffffff;
}

.services-block-two.style-two .inner-box .text {
    color: rgba(255, 255, 255, 0.65);
}

.offer-section.style-two {
    padding-top: 130px;
}


/*** 
====================================================================
	Call To Action Section
====================================================================
***/

.call-to-action-section {
    position: relative;
    padding: 60px 0px 60px;
    background-color:var(--primary-color);
    margin-bottom: 0px;
}

.quality-services {
    background-color: #5b4063;
}

.call-to-action-section:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.03;
    /* background: url(../images/background/pattern-3.png) center center no-repeat; */
}

.call-to-action-section h2 {
    position: relative;
    color: #ffffff;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2em;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.call-to-action-section p {
    color: #ffffff;
}

.call-to-action-section .btn-column {
    position: relative;
    text-align: center;
}

.work-single-last::after {
    display: none;
}

.work-single {
    padding: 13px;
}

.work-single h4 {
    color: #fff;
    font-weight: 600;
    font-size: 22px;
}

.call-to-action-section .btn-column .theme-btn {
    margin-top: 20px;
}
.call-to-action-section .btn-column a {
    position: relative;
    padding: 11px 40px;
    line-height: 24px;
    text-transform: capitalize;
    background: #ffffff;
    color: var(--third-color);
    font-size: 18px;
    font-weight: 500;
    border: 2px solid #ffffff;
    font-family: 'Poppins', sans-serif;
    border-radius: 50px;
    text-decoration: none;
}
.call-to-action-section .btn-column a:hover {
    background: none;
    color: #ffffff;
    border-color: #ffffff;
}



/* industres serve */

.we-serve {
    width: 100%;
    background: #fff
}

.industries-text {
    font-size: 1.2rem;
}

.industries-box {
    background-color: #fff;
    transition: transform .3s;
    border: solid 1px #d8d8d8;
    padding: 15px;
    position: relative;
    margin-top: 60px;
    width: 100%;
    height: 160px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center
}

.industries-box h3 {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    color: #004b68;
    text-align: center;
}

.industries-icon {
    width: 80px;
    position: relative;
    height: 80px;
    border: solid 1px #d8d8d8;
    border-radius: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    animation: pulse 2s infinite
}

.industries-icon {
    color: var(--primary-color);
    font-size: 40px;
    transition: transform .3s
}

.industries-box:hover {
    background: var(--primary-color);
    transition: all 0.7s;
}

.industries-box:hover h3 {
    color: #fff
}

.industries-box:hover .industries-icon {
    border: solid 1px var(--secondary-color);
    transition: all 0.7s;
}

.industries-box:hover .industries-icon {
    font-size: 46px
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 var(--secondary-color)
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(254, 141, 99, 0)
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(2254, 141, 99, 0)
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 var(--secondary-color);
        box-shadow: 0 0 0 0 var(--secondary-color)
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(254, 141, 99, 0);
        box-shadow: 0 0 0 10px rgba(254, 141, 99, 0)
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(254, 141, 99, 0);
        box-shadow: 0 0 0 0 rgba(254, 141, 99, 0)
    }
}

.industries-conversions-box {
    background-color: var(--primary-color);
    padding: 60px 0;
    border-radius: 5px;
}

.conversions-data {
    text-align: center;
}

.conversions-data p {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
    font-size: 15px;
}

.conversions-data h2 {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
}

.conversions-data a {
    color: var(--primary-color);
    font-family: 'Poppins', sans-serif;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    border-radius: 50px;
    padding: 12px 40px;
}

.conversions-data a {
    text-decoration: none;
}
.conversions-data a:hover {
    background-color: var(--primary-color);
    transition: all 0.5s ease;
    color: #ffffff;
    text-decoration: none;
}

/* reach-out */

.reach-out {
    background-color: var(--secondary-color);
    padding: 50px 0;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
}

.reach-out h1 {
    line-height: 45px;
}

.reach-out .reach-out-bold {
    font-weight: 700;
}

.reach-out-number {
    text-align: start;
}

.reach-out-number a {
    text-decoration: none;
    color: #ffffff !important;
}

.reach-out-number a:hover {
    color: #f2f2f2 !important;
}


/*Post Widget*/

.sidebar .popular-posts .post {
    position: relative;
    font-size: 14px;
    color: #666666;
    padding: 0px 0px;
    padding: 25px 25px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border: 2px solid #f6f6f6;
}

.sidebar .popular-posts .post:last-child {
    margin-bottom: 0px;
}

.sidebar .popular-posts .post .text {
    position: relative;
    top: -4px;
    font-size: 19px;
    margin: 0px 0px 0px;
    font-weight: 600;
    color: #000000;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

.sidebar .popular-posts .post .text a {
    color: #000000;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post a,
.sidebar .popular-posts .post a:hover {
    color: var(--primary-color);
}

.sidebar .popular-posts .post-info {
    font-size: 16px;
    color: #b1b1b1;
    margin-top: 6px;
}



/* Footer css Start */

.main-footer {
    position: relative;
    background-color: var(--primary-color);
}

.main-footer .footer-upper {
    position: relative;
    padding: 20px 0px 0px;
}

.main-footer .footer-upper .footer-column {
    position: relative;
    margin-bottom: 20px;
}

.main-footer .footer-widget h2 {
    position: relative;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 22px;
    text-transform: capitalize;
    font-family: 'Lato', sans-serif;
}
.main-footer .footer-widget p a {
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
}
.main-footer .footer-widget p a:hover {
    color: var(--third-color);
}

.main-footer .about-widget {
    position: relative;
}

.main-footer .about-widget h3 {
    position: relative;
    font-size: 28px;
    color: #ffffff;
    font-weight: 600;
    line-height: 1.2em;
}

.main-footer .about-widget p {
    position: relative;
    font-size: 15px;
    margin-top: 10px;
    line-height: 1.8em;
    margin-bottom: 15px;
    color: #ffffff;
}

.learn-more-btn {
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 24px;
    border-width: 0px;
    margin: 0px 0px 0px 8px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 15px;
}

.main-footer .links-widget .list {
    position: relative;
    padding: 0;
}

.main-footer .links-widget .list li {
    position: relative;
    margin-bottom: 10px;
}

.main-footer .links-widget .list li a {
    position: relative;
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.main-footer .links-widget .list li a:hover {
    color: var(--third-color);
}

.main-footer .footer-bottom {
    position: relative;
    border-top: 1px solid #ffffff;
}

.main-footer .footer-bottom .copyright {
    position: relative;
    padding: 20px 0px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding-bottom: 10px;
}
.widget-content ul {
    padding-left: 0;
}
.copyright a {
    text-decoration: none;
    color: var(--third-color);
    font-size: 18px;
}
.copyright a:hover {
    color: var(--third-color);
}
.copyright-links {
    text-align: center;
}

.copyright-links li {
    padding: 0px 5px 0px 5px;
    margin: 0px;
    font-size: 13px;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 20px;
}

.copyright-links li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
}
.copyright-links ul {
    margin: 0;
}

.copyright-links li a:hover {
    color: var(--third-color);
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.list-style-two li {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 1.8em;
    color: rgba(255, 255, 255, 0.60);
}

.list-style-two {
    position: relative;
}

.presentation-footer {
    background: var(--primary-color);
    float: none;
    height: auto;
    padding: 8px;
    position: relative;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.presentation-footer h4 {
    color: #fff;
    font-size: 16px;
    margin: 0;
}

.presentation-footer span.sec {
    right: 10px;
    position: absolute;
    top: 5px;
}

.Iso {
    margin-top: 15px;
}

.sec img {
    vertical-align: baseline;
}

/*Social Icon One*/

.social-icon-one {
    position: relative;
    display: flex;
    padding: 0;
    font-size: 25px;
}

.social-icon-one .fa {
    margin-right: 8px;
    color: white;
}
.social-icon-one .fa:hover {
    color: var(--third-color);
    transition: 0.7s all;
}


/* Page Head */

.svgBottom  {
    transform: rotate(180deg);
    margin-bottom: 25px;
}
.pageHead .pageHead_company_title {
    color: var(--primary-color);
    font-size: 21px;
    font-weight: 600;
    text-align: center;
}
.pageHead .pageHead_head_title {
    color: #16162E;
    font-size: 65px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    margin-top: 16px;
}
.pageHead .pageHead_details {
    color: #aaaab3;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 50px 0;
    line-height: 1.2;
}
.pageHead .pageHead_button {
    text-align: center;
    margin-bottom: 40px;
}
.pageHead .pageHead_button .btn {
    background-color: var(--primary-color);
    border-radius: 50px;
    padding: 10px 30px;
    text-decoration: none;
    border: 2px solid var(--secondary-color);
    font-size: 20px;
    animation: lets_button 1.5s infinite;
}
.pageHead .pageHead_button .btn a{
    text-decoration: none;
    color: #fff;
}
.pageHead .pageHead_button .btn:hover {
    background-color: var(--secondary-color);
}

/* AboutUs Page */

.aboutUsSection2 {
    margin: 25px 0;
}
.aboutUsSection2 .aboutUsSection2_image  {
    padding: 15px;
}
.aboutUsSection2 .aboutUsSection2_image img {
    border-radius: 8px;
}
.aboutUsSection2 .aboutUsSection2_title  {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 15px;
}
.aboutUsSection2 p  {
    font-size: 18px;
    text-align: justify;
    margin-left: 15px;
}


/* contact us page */


.contactUs .contactUs_title h1 {
    text-align: center;
}
.contactUs .contactUs_title span {
    color: var(--primary-color);
}
.contactUs .contactUs_form {
    margin: auto;
}

.contactUs .contactUs_form .contactUs_form_outerFrame {
    margin-bottom: 50px;
    border: 1px solid transparent;
    padding: 18px 10px;
    border-radius: 20px;
    background-color: var(--primary-color);
}
.contactUs .contactUs_form .contactUs_form_innerFrame {
    border: 1px solid transparent;
    padding: 18px 10px;
    border-radius: 10px;
    background-color: #fff;
}
.contactUs .contactUs_form .contactUs_form_inside {
    padding: 10px 20px;
    text-align: center;
}
.contactUs .contactUs_form .contactUs_form_inside form {
    padding: 0px 30px;
}
.contactUs .contactUs_form .contactUs_form_inside form input {
    border-radius: 50px;
    padding: 10px 20px;
}
.contactUs .contactUs_form .contactUs_form_inside form select {
    border-radius: 50px;
    padding: 10px 20px;
}
.contactUs .contactUs_form .contactUs_form_inside form textarea {
    border-radius: 20px;
    padding: 10px 20px;
}
.contactUs .contactUs_form .contactUs_form_inside form button:hover {
    border-radius: 50px;
    padding: 13px 50px;
    background-color: var(--secondary-color);
    color: #fff;
}
.contactUs .contactUs_form .contactUs_form_inside form button {
    border-radius: 50px;
    padding: 13px 50px;
    background-color: var(--primary-color);
    color: #fff;
    animation: lets_button 1.5s infinite;
}

/* privacy & Policy */
.privacyPolicy {
    text-align: justify;
    margin: 20px 25px;
}
.privacyPolicy .span{
    font-weight: 400;
    line-height: 1.3;
    font-size: 1.25rem;
}
.privacyPolicy .span a {
 text-decoration: none;
 color: var(--primary-color);
}
.privacyPolicy .span a:hover {
    border-bottom: 1px solid var(--primary-color);
}
.privacyPolicy ul   {
   list-style-type: none;
}
.privacyPolicy ul li::before  {
    content: "\2713";
    color: var(--primary-color);
    font-weight: 900;
    font-size: 1.25rem;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
    margin-right: 10px;
}

/* services page */
.tabComponent{
    cursor: pointer;
}
.allServices{
    margin-bottom: 15px;
}

.allServices .ServiceActiveClass{
    font-size: 20px;
    background-color: var(--primary-color);
    color: #fff;
    border-right: 2px solid white;
}
.allServices .ServiceNotActiveClass {
    font-size: 19px;
    background-color: var(--third-color);
    color: #fff;
    border-right: 2px solid white;
}

/* IT Solutions Services */
.itSolutionsServices .itSolutionsServices_title {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: var(--black-color)
}
.remote_tech_service{
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 15px;
}
.remote_tech_service span{
    border-bottom: 3px outset var(--third-color);
}
.itSolutionsServices_remote_tech .remote_tech_img img{
    border-radius: 10px;
    transition:  all 0.5s;
    transform: scale(0.8);
}
.itSolutionsServices_remote_tech .remote_tech_img img:hover{
    transform: scale(0.9);
}
.itSolutionsServices_remote_tech .remote_tech_text_title p{
    font-size: 2rem;
    font-weight: 600;
}
.itSolutionsServices_remote_tech .remote_tech_text_title p span{
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--primary-color);
}
.itSolutionsServices_remote_tech .remote_tech_text .remote_tech_text_detail{
    line-height: 1.5;
    font-size: 1.1rem;
}
.remote_tech .remote_tech_icons {
    font-size: 20px;
}
.remote_tech .remote_tech_icons span{
    color: #FFF;
    background-color: var(--primary-color);
    padding: 8px 15px 15px 15px;
    font-size: 20px;
    width: 20px;
    border-radius: 51px;
}
.remote_tech_details .remote_tech_details_1{
    align-items: center;
}
.remote_tech_details_1{
    margin: 15px 0;
}
.remote_tech_details .remote_tech_details_1_text{
    margin: 0px 80px 0 70px;
    font-size: 1.3rem;
    font-weight: 600;
}
.remote_tech_details .remote_tech_details_1_text a{
    text-decoration: none;
    color: var(--black-color);
}
.remote_tech_details .remote_tech_details_1_text a:hover{
    color: var(--primary-color);
}
.manage_it_service .title{
    margin-top: 10px;
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 15px;
}
.manage_it_service .title span{
    border-bottom: 3px outset var(--third-color);
}
.manage_it_service_detail .heading{
    font-size: 40px;
    font-weight: 600;
    margin-left: 25px;
    line-height: 1.2;
    margin-bottom: 10px;
}
.manage_it_service_detail .details{
    font-size: 24px;
    margin-left: 25px;
}
.manage_it_service_detail .image img{
    border-radius: 10px;
    transition:  all 0.5s;
    transform: scale(0.8);
}
.manage_it_service_detail .image img:hover{
    transform: scale(0.9);
}
.manage_it_service_detail .buttons {
    margin-top: 20px;
    margin-left: 25px;
}
.manage_it_service_detail .buttons .btn {
    background-color: var(--primary-color);
    border-radius: 50px;
    padding: 10px 30px;
    text-decoration: none;
    border: 2px solid var(--secondary-color);
    font-size: 20px;
    animation: lets_button 1.5s infinite;
}
.manage_it_service_detail .buttons .btn a{
    text-decoration: none;
    color: #fff;
}
.manage_it_service_detail .buttons .btn:hover {
    background-color: var(--secondary-color);
}


/* SoftwareAndMobileApp */


.SM_app .SM_app_title {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: var(--black-color)
}
.web_app_service{
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 15px;
}
.web_app_service span{
    border-bottom: 3px outset var(--third-color);
}
.web_text {
    text-align: center;
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
}
.web_app_section_1 .image img{
    border-radius: 10px;
    transition:  all 0.5s;
    transform: scale(0.8);
}
.web_app_section_1 .image img:hover{
    transform: scale(0.9);
}
.web_app_section_1 .question {
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 10px;
}
.web_app_section_1 .heading p span {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    padding: 10px 17px;
    border-radius: 20px 20px 20px 0;
    background-color: var(--primary-color);
}
.web_app_section_1 .heading p{
    font-size: 35px;
    font-weight: 500;
}
.web_app_section_1 .describe p{
    font-size: 20px;
}
.web_app_section_1 .contact_button .buttons {
    background-color: transparent;
    margin-top: 40px;
}
.web_app_section_1 .contact_button .buttons a{
    border: 1px solid var(--primary-color);
    padding: 20px 50px;
    background-color: transparent;
    border-radius: 20px 0px 20px 20px;
    font-size: 20px;
    text-decoration: none;
    color: var(--primary-color);
}
.web_app_section_1 .contact_button .buttons a:hover{
    background-color: var(--primary-color);
    color: #fff;
}
.web_app_section_2 .heading {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    padding: 10px 50px;
}
.web_app_section_2 .heading span{
    border-bottom: 3px solid var(--primary-color);
}
.web_app_section_2 .description {
    text-align: center;
    padding: 10px 50px;
    font-size: 17px;
}
.web_app_section_2_1 .image img{
    border-radius: 10px;
    transition:  all 0.5s;
    transform: scale(0.9);
}
.web_app_section_2_1 .image img:hover{
    transform: scale(1);
}
.web_app_section_2_1 .icon_box img{
    background-color: var(--primary-color);
    padding: 15px;
    border-radius: 15px;
    margin: 5px;
    transform: scale(0.9);
}
.web_app_section_2_1 .icon_box img:hover{
    background-color: var(--secondary-color);
    transform: scale(1);
    transition: all 0.4s;
}
.mobile_app_service{
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 15px;
}
.mobile_app_service span{
    border-bottom: 3px outset var(--third-color);
}
.mobile_text {
    text-align: center;
    font-size: 20px;
    padding: 15px;
    margin: 0;
}

.mobile_app_section_1 .image img{
    border-radius: 10px;
    transition:  all 0.5s;
    transform: scale(0.9);
}
.mobile_app_section_1 .image img:hover{
    transform: scale(1);
}
.mobile_app_section_1 .details .heading{
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
}
.mobile_app_section_1 .details .description{
    font-size: 18px;
}
.mobile_app_section_1 .contact_button .buttons {
    background-color: transparent;
    margin-top: 40px;
}
.mobile_app_section_1 .contact_button .buttons a{
    border: 1px solid var(--primary-color);
    padding: 20px 50px;
    background-color: transparent;
    border-radius: 50px;
    font-size: 20px;
    text-decoration: none;
    color: var(--primary-color);
}
.mobile_app_section_1 .contact_button .buttons a:hover{
    background-color: var(--secondary-color);
    color: #fff;
    border: 1px solid transparent;
}


.UI_service .UI_title{
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 15px;
}
.UI_service .UI_title span{
    border-bottom: 3px outset var(--third-color);
}
.UI_service .UI_text {
    font-size: 20px;
    padding: 15px;
    margin: 0;
}
.UI_section_1 {
    margin: 15px 0;
    background-color: var(--secondary-color);
}
.UI_section_1 .ui_card {
    border: 1px solid transparent;
    padding: 30px 25px 20px 30px;
    border-radius: 10px;
    background-color: #fff;
    margin: 25px;
}
.UI_section_1 .ui_card .card_title{
    margin-bottom: 25px;
}

.UI_section_1 .ui_card .card_heading{
    font-size: 20px;
    margin-left: 20px;
}
.UI_section_1 .ui_card .card_description{
    font-size: 15px;
}
.UI_section_1 .ui_card:hover{
    background-color: var(--primary-color);
    color: #fff;
}

/* Website Development & Services. */

.web_dev_title {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: var(--black-color)
}
.web_des_service{
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 15px;
}
.web_des_service span{
    border-bottom: 3px outset var(--third-color);
}
.des_text {
    text-align: center;
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
}
.web_des_section_1 .image img{
    border-radius: 10px;
    transition:  all 0.5s;
    transform: scale(0.8);
}
.web_des_section_1 .image img:hover{
    transform: scale(0.9);
}
.web_des_section_1 .heading p span {
    color: var(--primary-color);
    font-size: 35px;
    font-weight: 600;
}
.web_des_section_1 .heading p{
    font-size: 35px;
    font-weight: 500;
}
.web_des_section_1 .describe p{
    font-size: 20px;
}
.web_des_section_1 .list ul li{
    font-size: 21px;
    font-weight: 500;
    margin: 10px 0;
    list-style: inside;
}
.web_des_section_2 .heading {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    padding: 10px 50px;
}
.web_des_section_2 .heading span{
    border-bottom: 3px solid var(--primary-color);
}
.web_des_section_2 .description {
    text-align: center;
    padding: 10px 50px;
    font-size: 17px;
}
.web_des_section_2_1 .all_cards{
    background-color: rgb(245, 245, 245);
    padding: 70px 30px;
    border-radius: 10px;
}
.web_des_section_2_1 .all_cards .card_data{
    padding: 15px 20px 70px 20px;   
    background-color: #000; 
    border-radius: 15px;
    color: #fff;
    border: 1px solid var(--primary-color);
    margin-bottom: 25px;
}
.web_des_section_2_1 .all_cards .card_data .card_icon img{
    margin-bottom: 40px; 
    padding: 5px; 
    background-color: #fff;
    border-radius: 50px;
}
.web_des_section_2_1 .all_cards .card_data .card_title{
    font-size: 21px;
    font-weight: 600;
}
.web_des_section_2_1 .all_cards .card_data .card_description{
    font-size: 17px;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 15px;
    padding: 10px 20px;
}
.web_des_section_2_1 .all_cards .card_data:hover{
    background-color: var(--secondary-color); 
    transition: all 0.3s;
    color: black;
    border: 1px solid red;
}
.web_des_section_2_1 .all_cards .list{
    margin-top: 15px;
}
.web_des_section_2_1 .all_cards .list ul li{
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
}
.web_des_section_2_1 .all_cards .list ul li:hover{
    color: var(--primary-color);
    transition: all 0.3s;
}
.web_des_section_2_1 .all_cards .list ul li .arrow{
    margin-right: 10px;
    font-size: 30px;
    font-weight: 500;
}
