:root {
    --primary-color: #14a800;
    --secondary-color: #57a0ac;
    --third-color: #253B80;
}

@media only screen and (max-width: 1400px) {
    .shop-banner:before {
        display: none;
    }
}

@media only screen and (max-width: 1340px) {
    .page-wrapper {
        overflow: hidden;
    }
    .services-section:before,
    .services-section:after,
    .offer-section .image-layer {
        display: none;
    }
    .Full-Scale-serviceNow {
        padding-top: 0px;
    }
    #why-mitiz-column {
        padding-left: 25px !important;
    }
    .data-reports {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .services-block-four {
        margin-bottom: 0px;
    }
    .services-block-four .inner-box {
        padding: 10px 20px 10px;
    }
    .Web-development-services-section {
        padding-top: 30px;
    }
    .Web-development-services-section h2 {
        text-align: left !important;
    }
    .left-border {
        padding: 0 0 0 15px;
        border-color: #fff;
    }
    .services-block-five {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 1140px) {
    .mvp-heading h3 {
        font-size: 25px;
    }
    .mvp-banner-what-we img {
        left: 0px;
    }
    .main-menu .navigation>li {
        margin-right: 5px !important;
    }
    .page-wrapper {
        overflow: hidden;
    }
    .gallery-section .gallery-outer .content-column,
    .gallery-section .gallery-outer .gallery-column {
        position: relative;
        width: 100%;
    }
    .gallery-section .gallery-outer .content-column .content-inner .content .sec-title {
        max-width: 100%;
        margin-bottom: 0px;
    }
    .main-menu .navigation>li {
        padding: 20px 10px;
        margin-right: 16px;
    }
    .fixed-header .main-menu .navigation>li {
        padding: 18px 10px;
    }
    .case-block .inner-box .lower-box h3 {
        font-size: 18px;
    }
    .case-single .cases-inner .cases-title h2 {
        font-size: 30px;
    }
    .big-ipad-hidden {
        display: none !important;
    }
    .main-slider h2 {
        font-size: 48px !important;
        line-height: 1.4em;
    }
    .shop-page .sidebar-side .sidebar {
        margin-left: 0px;
    }
}

@media only screen and (min-width: 768px) {
    .main-menu .navigation>li>ul,
    .main-menu .navigation>li>ul>li>ul {
        display: block !important;
        visibility: hidden;
        opacity: 0;
    }
}

@media only screen and (max-width: 1023px) {
    .counter-section:before {
        position: relative;
    }
    .main-menu .navbar-collapse>.navigation>li>a {
        font-size: 15px !important;
    }
    .fact-counter .row {
        margin: 0px;
    }
    .Why-hire-Mitiz {
        padding-top: 0px;
    }
    .portfolio-content {
        padding: 10px;
        width: 45%;
        float: left;
    }
    .services-area-color {
        margin-bottom: 50px;
    }
    .mt-0 {
        margin-top: 0px !important;
    }
    .call-act,
    .why-choose-data-analysis {
        margin-bottom: 40px;
    }
    .devops-focus {
        padding-bottom: 0px;
    }
    .sec-title {
        margin-bottom: 10px;
    }
    .best-loging {
        margin-top: 0px;
    }
    /* .breadcrumb-area-bg-img {
		background-color: #135d5e !important;
		background-image: none !important;
	} */
    .testimonial-section .owl-dots {
        position: relative;
        text-align: center;
        padding: 0px 0px 20px;
    }
    .gallery-section {
        position: relative;
        background-color: #f8f9ff;
        padding: 10px 0px 0px;
    }
    .contect-details-box {
        margin-bottom: 20px;
        height: 100px;
    }
    .whyy-mitiz-bottom {
        width: 100%;
        float: left;
        margin: 5px 0 0 0;
        text-align: left;
    }
    .whyy-mitiz-bottom-image h2 {
        width: 100%;
        float: left;
        color: #FFF;
        font-size: 16px !important;
        margin: 0 0 16px 0;
        padding: 0 0 0 0;
    }
    .tick p img {
        vertical-align: middle;
        float: left;
        margin: 5px 22px 50px 0;
    }
    .subscribe-style-one .form-group {
        text-align: left;
    }
    .tp-parallax-wrap {
        position: absolute;
        top: 437px;
    }
    .btn-estimate {
        padding: 7px 15px !important;
    }
    .apps-bannar-img,
    .btn-estimate:before,
    .btn-estimate:after {
        display: none;
    }
    .header-style-two .search-box-outer .dropdown-menu {
        top: 61px;
    }
    .services-section .services-title h2 {
        text-align: left;
    }
    .services-section .services-title h2,
    .audit-form h2,
    .counter-section .content-column .content-inner h2,
    .business-section .content-column h2,
    .call-to-action-section h2,
    .marketing-section h2 {
        font-size: 25px;
    }
    .business-section .content-column h2 {
        margin-bottom: 0px;
    }
    .audit-form .row {
        margin: 0px -15px;
    }
    .audit-form .column .row .form-group {
        padding: 0px 15px;
    }
    .counter-section .image-column .image-outer {
        top: 0px;
    }
    .counter-section .image-column .image-outer .image img {
        position: relative;
        width: 100%;
        max-width: none;
    }
    .counter-section .content-column,
    .counter-section .image-column,
    .gallery-section .gallery-outer .content-column,
    .gallery-section .gallery-outer .gallery-column {
        position: relative;
        width: 100%;
    }
    .counter-section .content-column .content-inner {
        padding-left: 15px;
        padding-bottom: 60px;
    }
    .counter-section .image-column {
        margin-bottom: 40px;
        padding-left: 15px;
    }
    .gallery-section .gallery-outer .content-column .content-inner {
        padding-bottom: 65px;
    }
    .sidebar-page-container .sidebar-side .blog-sidebar {
        margin-left: 0px;
    }
    .sec-title h2 br,
    .main-menu .navigation>li.dropdown>a:before,
    .faq-section .form-column:before,
    .faq-section .form-column .form-inner h2 br,
    .sidebar-page-container .sidebar-side::before,
    .sidebar-page-container .sidebar-side .blog-sidebar:before {
        display: none;
    }
    .call-to-action-section,
    .call-to-action-section .btn-column,
    .subscribe-style-one,
    .call-to-action-two,
    .call-to-action-two .btn-column {
        text-align: center;
    }
    .subscribe-style-one h2,
    .call-to-action-two h2 {
        font-size: 32px;
        margin-bottom: 20px;
    }
    .main-menu .navigation>li.dropdown a {
        padding-right: 0px;
    }
    .page-title h1 {
        font-size: 36px;
    }
    .services-block-four .inner-box .content h3 {
        font-size: 20px;
    }
    .ipad-hidden {
        display: none !important;
    }
    .main-slider .text br {
        display: none;
    }
    .main-slider h2 {
        font-size: 48px !important;
        /* padding-top: 230px; */
        padding-bottom: 0px;
    }
    .apps-banner {
        height: 460px;
    }
    .Learn-more-btn {
        margin: 0px;
    }
    .services-section .services-title h2 {
        margin-bottom: 15px;
    }
    .services-section .services-title {
        padding-bottom: 25px;
    }
    .gallery-section .gallery-outer .content-column .content-inner {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 880px) {}

@media only screen and (max-width: 767px) {
    .u-table-responsive {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .digital-marketing-services .inner-content {
        padding-top: 0px;
    }
    .u-table-responsive thead th,
    .u-table-responsive tfoot th,
    .u-table-responsive thead td,
    .u-table-responsive tfoot td {
        white-space: nowrap;
    }
    .extensive-approach .image-column {
        padding-top: 20px;
    }
    .about-seo .inner-content p {
        padding-bottom: 5px !important;
        text-align: center;
    }
    .success-stories {
        background-color: #135d5e;
        padding: 50px 0px 50px 0px;
        margin-top: 0px;
    }
    .happy-customer .image img {
        position: static;
    }
    .success-stories .testimonial {
        margin: 0 30px 40px;
    }
    .client-video-section iframe {
        padding: 10px;
    }
    .testimonial-content-box {
        margin-bottom: 0px;
    }
    .happy-customer .inner-content {
        padding: 10px;
    }
    .client-video-section {
        margin-top: 50px;
    }
    .client-video-section .inner-content {
        padding-top: 20px;
        padding-left: 0px;
    }
    .header-upper .logo-outer {
        position: relative;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        margin: 0px;
    }
    .wp-divide {
        height: auto;
        padding-bottom: 50px;
    }
    .col-half-offset {
        margin: 0px;
    }
    .services-block-two .inner-box h3 {
        font-size: 14px;
    }
    .services-block-two .inner-box {
        min-height: 250px;
    }
    .data-manage-image {
        padding-top: 10px !important;
    }
    .work-single::after {
        font-family: FontAwesome;
        content: "\f078";
        font-size: 41px;
        font-weight: 900;
        color: #fff;
        top: 100%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }
    .work-single {
        padding: 20px;
    }
    .angular-banner {
        padding-top: 0px;
    }
    .custombox ul li {
        width: 100%;
    }
    .inner-content h2 {
        font-size: 25px;
    }
    .Why-service-now {
        margin-bottom: 50px;
    }
    .custombox h3 {
        font-size: 20px;
    }
    .Portfolio-heading h2 {
        font-size: 25px;
    }
    .port-work h2 {
        margin-bottom: 20px;
    }
    .call-act,
    .why-choose-data-analysis {
        margin-bottom: 40px;
    }
    .Ai-india {
        margin-top: 0px;
    }
    .Top-page-title {
        margin-bottom: 30px;
    }
    .news-style-one .inner-box .lower-content {
        margin-bottom: 30px;
    }
    .Latest-Blogs-sec h2 {
        margin-bottom: 40px;
        font-size: 31px !important;
    }
    .breadcrumb-area-bg-img:before {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        opacity: 0.02;
        background-size: cover;
        /* background: url(../images/background/pattern-4.png) center center no-repeat; */
    }
    .breadcrumb-area-bg-img h1 {
        font-size: 16px;
        text-align: left;
    }
    .breadcrumb-area-bg-img .bread-crumb {
        position: relative;
        padding-top: 15px;
        text-align: left;
        background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
        padding: 0px 10px;
        display: inline-block;
        margin-top: 0px;
    }
    .breadcrumb-area-bg-img .bread-crumb li {
        font-size: 12px;
    }
    .content-column p {
        margin-bottom: 0px;
    }
    .content-column h2 {
        font-size: 25px;
    }
    .breadcrumb-area-bg-img {
        /* position: relative;
		color: #ffffff;
		padding: 141px 0px 0px;
		background-color: #17222b;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat; */
        /* height: 200px; */
        position: relative;
        color: #ffffff;
        /* padding: 98px 0px 0px; */
        /* background-color: #17222b; */
        /* background-size: contain; */
        background-position: center center;
        background-repeat: no-repeat;
        height: 200px;
        background-position-x: right;
    }
    .breadcrumb-column {
        padding-top: 5px;
    }
    .blog-details-discriptions {
        padding: 15px;
    }
    .blog-single {
        padding: 25px 0px 15px !important;
    }
    .sponsors-section .sponsors-title h2 {
        font-size: 20px;
    }
    .business-section .image-column {
        margin-bottom: 15px;
    }
    .business-section .content-column p {
        padding-top: 10px;
        margin-bottom: 0px;
        padding-bottom: 15px;
    }
    .sec-title {
        margin-bottom: 0px;
    }
    .news-style-one .inner-box .lower-content-blog h3 {
        position: relative;
        font-weight: 500;
        font-size: 18px;
        color: #13192d;
        margin-bottom: 0px;
        line-height: 1.3em;
        display: block;
        display: -webkit-box;
        max-width: 400px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .portfolio-details-back {
        text-align: left;
    }
    .project-details-img {
        height: 150px;
        background-size: 100%;
    }
    .case-single {
        position: relative;
    }
    .contect-details-box {
        margin-bottom: 20px;
        height: auto;
    }
    .portfolio-thumb {
        width: 100%;
        right: 0;
        margin: 0 auto;
        max-width: 100%;
        float: none;
        left: auto;
        position: absolute;
    }
    .product-description-border {
        right: auto;
        left: 0;
        width: 100%;
    }
    .portfolio-content {
        padding: 32px;
        padding-top: 0px;
        width: 100%;
        float: left;
        padding-top: 115px;
    }
    .subscribe-style-one {
        padding: 45px 0px 150px;
    }
    .portfolio-description-border {
        margin-left: 0px;
        margin-top: 90px;
    }
    .product-description-right-border {
        width: 100%;
    }
    .portfolio-right-thumb {
        width: 100%;
        position: absolute;
        left: auto;
        top: -120px;
    }
    .shorting-menu .filter {
        margin-bottom: 10px;
    }
    .services-Consultancy-box {
        padding: 5px 15px 15px;
    }
    .page-title {
        padding: 150px 0px 90px;
        background-color: #4a4a4a;
    }
    .why-mitiz-list {
        width: 100%;
    }
    .business-section .row {
        display: flex;
        flex-flow: column;
    }
    .business-section {
        position: relative;
        padding: 0px 0px 20px;
    }
    #agile-image {
        order: 2;
    }
    #why-mitiz-column {
        order: 1;
    }
    .services-section {
        padding: 35px 0px 0px;
    }
    .offer-section,
    .team-section {
        padding-top: 15px;
    }
    .testimonial-section {
        padding: 0px 0px 10px;
    }
    .news-section {
        position: relative;
        padding: 45px 0px 10px;
    }
    .sponsors-section {
        position: relative;
        padding: 5px 0px 75px;
    }
    .presentation-footer {
        width: 82%;
    }
    .gallery-section {
        padding: 5px 0px 0px;
    }
    .btn-estimate::before,
    .btn-estimate::after {
        display: none;
    }
    .btn-estimate {
        border-radius: 0px;
    }
    .tp-parallax-wrap {
        position: absolute;
        top: 437px;
    }
    .main-slider h2 {
        max-width: 177px;
    }
    .banner-type-text {
        padding: 130px 0px 30px 0px;
    }
    .sec-title h2 {
        font-size: 24px;
    }
    .main-menu .collapse {
        max-height: 300px;
        overflow: auto;
        float: none;
        width: 100%;
        padding: 10px 0px 0px;
        border: none;
        margin: 0px 0px 15px;
        -ms-border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        box-shadow: none;
    }
    .main-menu .collapse.in,
    .main-menu .collapsing {
        padding: 10px 0px 0px;
        border: none;
        margin: 0px 0px 15px;
        -ms-border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        box-shadow: none;
    }
    .main-menu .navbar-header {
        position: relative;
        float: none;
        display: block;
        text-align: right;
        width: 100%;
        padding: 0px 0px 20px;
        right: 0px;
    }
    .main-menu .navbar-header .navbar-toggle {
        display: inline-block;
        z-index: 20;
        border: 1px solid #ff5926;
        float: none;
        margin: 0px 0px 0px 0px;
        border-radius: 0px;
        background: #ff5926;
    }
    .main-menu .navbar-header .navbar-toggle .icon-bar {
        background: #ffffff;
    }
    .main-menu .navbar-collapse>.navigation {
        float: none !important;
        margin: 0px !important;
        width: 100% !important;
        border: 1px solid #ffffff;
        border-top: none;
    }
    .main-menu .navbar-collapse>.navigation>li {
        margin: 0px !important;
        float: none !important;
        padding: 0px !important;
        width: 100%;
    }
    .main-menu .navigation>li>a,
    .main-menu .navigation>li>ul:before {
        border: none;
    }
    .main-menu .navbar-collapse>.navigation>li>a {
        padding: 10px 10px !important;
        border: none !important;
        font-size: 15px !important;
    }
    .main-menu .navigation li.dropdown>a:after,
    .main-menu .navigation>li.dropdown>a:before,
    .main-menu .navigation>li>ul>li>a::before,
    .main-menu .navigation>li>ul>li>ul>li>a::before {
        color: #ffffff !important;
        right: 15px;
        font-size: 16px;
        display: none !important;
    }
    .main-menu .navbar-collapse>.navigation>li>ul,
    .main-menu .navbar-collapse>.navigation>li>ul>li>ul {
        position: relative;
        border: none;
        float: none;
        visibility: visible;
        opacity: 1;
        display: none;
        margin: 0px;
        left: auto !important;
        right: auto !important;
        top: auto !important;
        padding: 0px;
        outline: none;
        width: 100%;
        background: #333333;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        transition: none !important;
        -webkit-transition: none !important;
        -ms-transition: none !important;
        -o-transition: none !important;
        -moz-transition: none !important;
    }
    .main-menu .navbar-collapse>.navigation>li>ul,
    .main-menu .navbar-collapse>.navigation>li>ul>li>ul {
        border-top: 1px solid rgba(255, 255, 255, 1) !important;
    }
    .main-menu .navbar-collapse>.navigation>li,
    .main-menu .navbar-collapse>.navigation>li>ul>li,
    .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li {
        border-top: 1px solid rgba(255, 255, 255, 1) !important;
        border-bottom: none;
        opacity: 1 !important;
        top: 0px !important;
        left: 0px !important;
        visibility: visible !important;
    }
    .main-menu .navbar-collapse>.navigation>li>ul>li:first-child,
    .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li:first-child {
        border-top: none !important;
    }
    .main-menu .navbar-collapse>.navigation>li:first-child {
        border: none;
    }
    .main-menu .navbar-collapse>.navigation>li>a,
    .main-menu .navbar-collapse>.navigation>li>ul>li>a,
    .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li>a {
        padding: 10px 10px !important;
        line-height: 22px;
        color: #ffffff;
        background: #333333;
        text-align: left;
    }
    .main-menu .navbar-collapse>.navigation>li>a:hover,
    .main-menu .navbar-collapse>.navigation>li>a:active,
    .main-menu .navbar-collapse>.navigation>li>a:focus {
        background: #ff5926;
    }
    .main-menu .navbar-collapse>.navigation>li:hover>a,
    .main-menu .navbar-collapse>.navigation>li>ul>li:hover>a,
    .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li:hover>a,
    .main-menu .navbar-collapse>.navigation>li.current>a,
    .main-menu .navbar-collapse>.navigation>li.current-menu-item>a {
        background: #333333;
        color: #ffffff !important;
    }
    .main-menu .navbar-collapse>.navigation li.dropdown .dropdown-btn,
    .header-style-two .nav-outer .btn-outer {
        display: block;
    }
    .main-menu .navbar-collapse>.navigation li.dropdown:after,
    .main-menu .navigation>li>ul:before,
    .main-slider .bordered-layer {
        display: none !important;
    }
    .search-box-outer {
        padding-left: 0px;
        border: 0px;
    }
    .fixed-header .info-options,
    .testimonial-section-two .single-item-carousel {
        padding: 0px !important;
    }
    .services-block-two .inner-box {
        padding: 20px 20px 20px;
    }
    .testimonial-style-one .inner-box {
        padding: 30px 20px 15px;
    }
    .services-block-four .inner-box .content {
        padding-left: 0px;
        text-align: center;
    }
    .services-block-four .inner-box .content .icon-box {
        position: relative;
        margin: 0 auto;
    }
    .testimonial-style-two .inner-box .text {
        font-size: 18px;
    }
    .testimonial-section-two .owl-nav,
    .news-style-one .inner-box .lower-content blockquote .blockquote-text br {
        display: none;
    }
    .page-title,
    .page-title .bread-crumb {
        text-align: center;
    }
    .error-section h3 {
        font-size: 32px;
    }
    .case-single .cases-inner .cases-title h2 {
        border: 0px;
    }
    .our-blogs .news-style-one .inner-box .lower-content h3 {
        font-size: 20px;
    }
    .news-style-one .inner-box .lower-content {
        padding: 40px 15px 20px 15px;
    }
    .blog-single .news-style-one .inner-box .lower-content h3 {
        font-size: 20px;
    }
    .news-style-one .inner-box .lower-content blockquote .blockquote-text {
        font-size: 28px;
    }
    .sidebar-page-container .comments-area .comment,
    .shop-page .prod-tabs .reviews-container .review-box {
        padding-left: 0px;
    }
    .sidebar-page-container .comments-area .comment-box .author-thumb,
    .shop-page .prod-tabs .reviews-container .rev-thumb {
        position: relative;
        top: 0px;
    }
    .news-style-one .inner-box .lower-content .text blockquote:before {
        font-size: 80px;
    }
    .news-style-one .inner-box .lower-content .text blockquote .quote-author {
        position: relative;
        bottom: 0px;
        right: 0px;
    }
    .shop-page .prod-tabs .reviews-container .rev-header .rating {
        margin-left: 0px;
    }
    .shop-page .prod-tabs .reviews-container .rev-text {
        margin-top: 15px;
    }
    .main-slider h2 {
        font-size: 36px !important;
    }
    .services-section .services-title h2 {
        font-size: 32px;
    }
}

@media only screen and (max-width: 599px) {
    .accordion-box .block .acc-btn {
        font-size: 18px;
    }
    .main-slider h2 {
        font-size: 23px !important;
        line-height: 2em;
    }
}

@media only screen and (max-width: 499px) {
    .header-style-two .header-lower .left-btn,
    .header-style-two .header-lower .right-info {
        position: relative;
        padding: 20px 0 32px;
    }
    .testimonial-style-one .inner-box .user-info .inner .quote {
        display: none;
    }
    .error-search-box {
        width: 100%;
    }
    .shop-page .prod-tabs .tab-btns .tab-btn {
        width: 100%;
        text-align: center;
        margin: 0px 0px 10px;
    }
    .related-products h2 {
        font-size: 24px;
    }
}


/* Larevel development css start  */

@media only screen and (max-width: 767px) {
    .kiosk-title {
        font-size: 25px !important;
    }
    .Laravel-development-solutions .inner-content {
        padding-top: 20px !important;
    }
    .Laravel-development-service h2 {
        padding: 5px;
        font-size: 25px !important;
    }
    .Laravel-development-service ul {
        display: inline-block;
        width: 100%;
        text-align: start;
        vertical-align: top;
        padding-left: 20px;
    }
    .Laravel-development-service-box h2 {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 25px !important;
    }
    .Laravel-development-service ul {
        display: inline-block;
        width: 100%;
        text-align: start;
        vertical-align: top;
        padding-left: 20px;
    }
    @media only screen and (max-width: 767px) {
        .kiosk-title {
            font-size: 25px !important;
        }
        .Laravel-development-solutions .inner-content {
            padding-top: 20px !important;
        }
        .Laravel-development-service h2 {
            padding: 5px;
            font-size: 25px !important;
        }
        .Laravel-development-service ul {
            display: inline-block;
            width: 100%;
            text-align: start;
            vertical-align: top;
            padding-left: 20px;
        }
        .Laravel-development-service-box h2 {
            margin-top: 5px;
            margin-bottom: 10px;
            font-size: 25px !important;
        }
        .Laravel-development-service ul {
            display: inline-block;
            width: 100%;
            text-align: start;
            vertical-align: top;
            padding-left: 20px;
        }
    }
    .Laravel-development-service ul li::before {
        position: absolute;
        left: -12px;
    }
    .Laravel-development-service {
        padding: 15px;
    }
    .scroll-to-top {
        bottom: 75px;
    }
    .social-fixed-social {
        bottom: 70px !important;
    }
    .kiosk-services-box p {
        font-size: 15px;
    }
    .kiosk-services-box {
        position: relative;
        margin-bottom: 0px;
    }
    .Laravel-Harness .image-column .image {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .price-table-plan h2 {
        font-size: 24px;
    }
    .price-table .u-text-1 {
        font-size: 20px;
    }
}


/* Larevel development css end  */


/* Dentistry Management css start */

@media only screen and (max-width: 767px) {
    .patient-management-box ul li {
        width: 100%;
        min-height: auto;
    }
    .patient-management-box {
        margin-top: 30px;
    }
    .mitiz-patient-management {
        margin-bottom: 20px;
    }
    .choosing-patient img {
        padding-top: 20px;
        text-align: right;
    }
    .dentistry-box {
        min-height: auto;
        margin-bottom: 20px;
    }
    .staff-collaboration {
        text-align: left;
    }
    .staff-collaboration img {
        padding-top: 10px;
    }
    .hr-application-management-system {
        background: #016064;
    }
    .hr-application-management-system .inner-content {
        padding-top: 20px;
    }
    .hr-application-management-system {
        padding: 60px 0px 50px 0px;
        margin-bottom: 20px;
    }
    .management-important .inner-content {
        padding-top: 0px;
    }
    .management-important .inner-content {
        margin-bottom: 30px;
    }
    .hr-modules {
        padding: 30px 0px 50px 0px;
        background: #016064;
    }
    .hr-modules .sec-title {
        margin-bottom: 30px;
    }
    .modules-box {
        min-height: auto;
    }
    .manage-box-green,
    .manage-box-orange {
        min-height: auto;
    }
    .hr-management-list-box {
        width: auto;
    }
    .function-hr-management .image-column .image {
        padding: 30px 0px 0px 0px;
    }
    .function-hr-management,
    .mitiz-help-companies {
        padding: 30px 0px 50px 0px;
    }
    .hr-management-list-box {
        margin-top: 30px;
    }
    .mitiz-help-companies .inner-content {
        margin-bottom: 30px;
    }
    .contact-details-box {
        padding: 5px 0px 5px 0px;
    }
}


/* Dentistry Management css end */